<template>
  <div>
    <ContentBlock class="auditContainer" v-if="currentInfo.auditStatus === '3'">
      <div>审核失败原因：{{ currentInfo.auditRemark }}</div>
    </ContentBlock>
    <ContentBlock style="margin-top: 8px;">
      <div class="top">
        <div class="left" @click="handleUpload">
          <el-avatar shape="square" :size="60" v-if="$store.state.company.currentCompanyInfo.logo"
            :src="$store.state.company.currentCompanyInfo.logo"></el-avatar>
          <template v-else>{{
      $store.state.company.currentCompanyInfo.name ? $store.state.company.currentCompanyInfo.name.substring(0, 2) : ''
    }}</template>
        </div>
        <div class="right">
          <h3>{{ $store.state.company.currentCompanyInfo.name }}</h3>
          <!-- <p v-if="currentInfo.status==='1'">企业未认证，认证后可维护企业档案，<span class="approveBtn">前往认证&nbsp;&nbsp;&gt;</span></p> -->
          <p>统一社会信用代码：{{ $store.state.company.currentCompanyInfo.certCode }}</p>
        </div>
      </div>
    </ContentBlock>
    <!-- 基本信息 -->
    <Basic :isShowEditing="true" :isDisabled="false" :currentInfo="currentInfo" @updateData="updateData" ref="JZBasic"
      style="margin-top: 8px;"></Basic>
    <!-- 证件/材料 -->
    <Material :isShowHead="true" :isDisabled="false" :currentInfo="currentInfo" @updateData="updateData"
      ref="JZMaterial" style="margin-top: 8px;"></Material>
    <!-- 联系与结算信息 -->
    <Contact :isShowHead="true" :isDisabled="false" :currentInfo="currentInfo" @updateData="updateData" ref="JZContact"
      style="margin-top: 8px;"></Contact>
    <!-- 服务能力证明材料 -->
    <Service :isShowHead="true" :isDisabled="false" :currentInfo="currentInfo" @updateData="updateData" ref="JZService"
      style="margin-top: 8px;"></Service>
    <!-- 资质审核表 -->
    <Qualification :isDisabled="false" :currentInfo="currentInfo" :isShowHead="true" @updateData="updateData"
      ref="JZQualification" @handleGenerate="handleGenerate" style="margin-top: 8px;"></Qualification>
    <div style="visibility: hidden;">
      <Upload v-model="imageLists" label="附件信息" buttonText="上传" type="single" ref="JZUpload" :show-file-list="false"
        @success="handleFileLists" @remove="handleRemove" accept="image/png,image/jpeg,image/jpg,application/pdf"
        :tip="false" :maxSize="10" size="origin" :limit="2">
      </Upload>
    </div>
    <bottom-panel>
      <template>
        <el-checkbox v-model="checked">已阅读并同意</el-checkbox>
        <span v-for="(item, index) in protocol" :key="'prot-xz-' + index" @click="toRead(item)" class="accord"><a>《{{
      item.name }}》</a>
        </span>
        <el-button style="margin-left: 1em" type="primary" plain @click="handleSave('1')">保存草稿</el-button>
        <el-button type="primary" @click="handleSave('2')">提交认证</el-button>
      </template>
    </bottom-panel>
  </div>
</template>

<script>
import Basic from './component/Basic';
import AvaterMixin from './mixins/avater';
import { getDetailTenantInformationInfo, updateTenantInformation, generateAuditFile } from '@/api/systemSetting';
import * as dayjs from 'dayjs';
import { tenantBaseInfo } from '@/api/company';

export default {
  name: '',
  mixins: [AvaterMixin],
  components: {
    Basic,
    Material: () => import('./component/Material/index.vue'),
    Contact: () => import('./component/Contact/index'),
    Service: () => import('./component/Service/index'),
    Qualification: () => import('./component/Qualification/index'),
  },
  created () {
    this.getDetailInfo();
  },
  methods: {
    updateData (form, key) {
      let tenantFileReqDTOs = (this.privateCurrentInfo.tenantFileReqDTOs || []).map(i => ({
        ...i,
        type: '5',
      }));
      let currentParams = {
        dataType: '2',
        operateType: '1',
        type: '1',
        referralCode: '',
        phoneNumber: this.privateCurrentInfo.phoneNumber,
        tenantBaseInfoReqDTO: {},
        tenantCertificateInfoReqDTOs: [],
        tenantContactInfoReqDTOs: [],
        tenantInvoiceInfoReqDTO: null,
        tenantServiceCapacityReqDTO: null,
        tenantServiceCapacityRecordReqDTOs: [],
        tenantFileReqDTOs: tenantFileReqDTOs || [],
      };
      if (key === 'tenantFileReqDTOs') {
        let qualificationInfo = this.$refs.JZQualification.form;
        currentParams['tenantFileReqDTOs'] = form;
        currentParams['phoneNumber'] = qualificationInfo.phoneNumber;
      } else if (key === 'tenantBaseInfoRespDTO') {
        currentParams['tenantBaseInfoReqDTO'] = form;
      } else if (key === 'tenantCertificateInfoRespDTOs') {
        currentParams['tenantCertificateInfoReqDTOs'] = (form || []).map(i => ({
          ...i,
          operateType: i.id ? '2' : '1',
        }));
      } else if (key === 'tenantContactInfoRespDTOs') {
        currentParams['tenantContactInfoReqDTOs'] = (form || []).map(i => ({
          ...i,
          operateType: i.id ? '2' : '1',
        }));
      } else if (key === 'tenantInvoiceInfoRespDTO') {
        currentParams['tenantInvoiceInfoReqDTO'] = form ? {
          ...form,
          operateType: form.id ? '2' : '1',
        } : null;
      } else if (key === 'tenantServiceCapacityRespDTO') {
        currentParams['tenantServiceCapacityReqDTO'] = form ? {
          ...form,
          operateType: form.id ? '2' : '1',
        } : null;
      } else if (key === 'tenantServiceCapacityRecordRespDTOs') {
        this.currentInfo['tenantServiceCapacityRecordRespDTOs'] = form;
        let tempLists = this.getServiceRecordLists();
        currentParams['tenantServiceCapacityRecordReqDTOs'] = tempLists;
      }
      updateTenantInformation(currentParams).then(() => {
        this.$message.success('保存成功！');
        this.getDetailInfo();
      });
    },
    async handleSave (operateType) {
      if (!this.checked && operateType === '2') {
        this.$message.warning('请选择已阅读并同意服务协议');
        return;
      }

      if (operateType === '2') {
        let flag0 = await this.$refs.JZBasic.handleValidateForm();
        let flag1 = await this.$refs.JZMaterial.handleValidateForm();
        let flag2 = await this.$refs.JZContact.handleValidateForm();
        let flag3 = await this.$refs.JZService.handleValidateForm();
        let flag4 = await this.$refs.JZQualification.handleValidateForm();
        if (flag0 && flag1 && flag2 && flag3 && flag4) {
          this.handleSubmit(operateType);
        } else {
          return;
        }
      } else {
        this.handleSubmit(operateType);
      }
    },
    async handleGenerate () {
      let flag0 = await this.$refs.JZBasic.handleValidateForm();
      let flag1 = await this.$refs.JZMaterial.handleValidateForm();
      let flag2 = await this.$refs.JZContact.handleValidateForm();
      let flag3 = await this.$refs.JZService.handleValidateForm();
      if (flag0 && flag1 && flag2 && flag3) {
        generateAuditFile({}).then(({ data }) => {
          const fileName = `资质审核表_${dayjs(Date.now()).format('YYYYMMDDHHmmss')}.pdf`;
          const blob = new Blob([data]);// 返回一个新的blob对象
          const a = document.createElement('a');// 创建a标签
          a.style.display = 'none';// a标签样式为隐藏
          const href = window.URL.createObjectURL(blob);// 创建window临时地址
          a.href = href;// 跳转地址
          a.setAttribute('download', fileName);
          document.body.appendChild(a);// 将指定的dom添加的body之后
          a.click();// 点击事件
          document.body.removeChild(a);// 移除dom
          window.URL.revokeObjectURL(href);// 释放url地址
        });
      }
    },
    handleSubmit (operateType, flag) {
      let tenantBaseInfoReqDTO = this.$refs.JZBasic.form;
      let qualificationInfo = this.$refs.JZQualification.form;
      let tenantFileReqDTOs = qualificationInfo.tenantFileReqDTOs.map(i => ({
        ...i,
        type: '5',
      }));
      let currentInfo = this.currentInfo;
      let tempLists = this.getServiceRecordLists();
      let currentParams = {
        dataType: '2',
        operateType,
        type: '1',
        referralCode: '',
        phoneNumber: qualificationInfo.phoneNumber,
        tenantBaseInfoReqDTO: tenantBaseInfoReqDTO,
        tenantCertificateInfoReqDTOs: (currentInfo.tenantCertificateInfoRespDTOs || []).map(i => ({
          ...i,
          operateType: i.id ? '2' : '1',
        })),
        tenantContactInfoReqDTOs: (currentInfo.tenantContactInfoRespDTOs || []).map(i => ({
          ...i,
          operateType: i.id ? '2' : '1',
        })),
        tenantInvoiceInfoReqDTO: currentInfo.tenantInvoiceInfoRespDTO ? {
          ...currentInfo.tenantInvoiceInfoRespDTO,
          operateType: currentInfo.tenantInvoiceInfoRespDTO.id ? '2' : '1',
        } : null,
        tenantServiceCapacityReqDTO: currentInfo.tenantServiceCapacityRespDTO ? {
          ...currentInfo.tenantServiceCapacityRespDTO,
          operateType: currentInfo.tenantServiceCapacityRespDTO.id ? '2' : '1',
        } : null,
        tenantServiceCapacityRecordReqDTOs: tempLists,
        tenantFileReqDTOs: tenantFileReqDTOs,
      };
      updateTenantInformation(currentParams).then(async () => {
        this.$message.success('保存成功！');

        await this.updateStatus();

        if (!flag) {
          const activeTabIds = this.$tabs.items.map((item) => item.id);

          if (activeTabIds.includes('/join/xiaozi')) {
            this.$tabs.refresh({
              name: 'JOIN_XIAOZI',
            });
          }

          if (activeTabIds.includes('/systemSetting/portal/home')) {
            this.$tabs.refresh({
              name: 'PortalHome',
            });
          }

          this.$router.back();
        } else {
          this.getDetailInfo();
        }
      });
    },
    async updateStatus () {
      const { body } = await tenantBaseInfo({});
      this.$local.remove('entryStatus');
      if (body) {
        this.entryStatus = {
          enteringStatus: body.enteringStatus,
          enteringAuditStatus: body.enteringAuditStatus,
          tenantServiceInfoStatus: body.tenantServiceInfoStatus,
          tenantServiceInfoOrderPayStatus: body.tenantServiceInfoOrderPayStatus,
        };
        this.$local.set('entryStatus', this.entryStatus);
      }
    },
    getServiceRecordLists () {
      let targetLists = (this.currentInfo.tenantServiceCapacityRecordRespDTOs || []).map(i => ({
        ...i,
        tenantServiceCapacityRecordDetailRespDTOs: i.tenantServiceCapacityRecordDetailRespDTOs.map(j => ({
          ...j,
          hospitalId: i.hospitalId,
          hospitalName: i.hospitalName,
          operateType: j.id ? '2' : j.operateType,
        })),
      })).map(i => i.tenantServiceCapacityRecordDetailRespDTOs).flat();
      let serviceLists = (this.privateCurrentInfo.tenantServiceCapacityRecordRespDTOs || []).map(i => ({
        ...i,
        tenantServiceCapacityRecordDetailRespDTOs: i.tenantServiceCapacityRecordDetailRespDTOs.map(j => ({
          ...j,
          hospitalId: i.hospitalId,
          hospitalName: i.hospitalName,
          operateType: j.id ? '2' : j.operateType,
        })),
      })).map(i => i.tenantServiceCapacityRecordDetailRespDTOs).flat();
      // 这里需要根据id判断
      let targetIdLists = targetLists.filter(i => i.id).map(i => i.id);
      let deleteLists = serviceLists.filter(i => !targetIdLists.includes(i.id)).map(i => ({
        ...i,
        operateType: '3',
      }));
      targetLists = [...targetLists, ...deleteLists];
      return targetLists;
    },
    toRead (item) {
      window.open(item.url, 'blank');
    },
    getDetailInfo () {
      getDetailTenantInformationInfo({
        type: '1',
        dataType: '2',
      }).then(({ body }) => {
        this.currentInfo = {
          auditRemark: body.auditRemark,
          auditStatus: body.auditStatus,
          dataType: '2',
          type: '1',
          referralCode: '',
          phoneNumber: body.phoneNumber,
          tenantBaseInfoRespDTO: body.tenantBaseInfoRespDTO || {},
          tenantCertificateInfoRespDTOs: body.tenantCertificateInfoRespDTOs || [],
          tenantContactInfoRespDTOs: body.tenantContactInfoRespDTOs || [],
          tenantInvoiceInfoRespDTO: body.tenantInvoiceInfoRespDTO || {},
          tenantServiceCapacityRespDTO: body.tenantServiceCapacityRespDTO || {},
          tenantServiceCapacityRecordRespDTOs: body.tenantServiceCapacityRecordRespDTOs || [],
          tenantFileReqDTOs: body.tenantFileReqDTOs || [],
        };
        this.privateCurrentInfo = JSON.parse(JSON.stringify(this.currentInfo));
      });
    },
  },
  data () {
    return {
      checked: false,
      currentInfo: {},
      privateCurrentInfo: {},
      companyInfo: {},
      protocol: [
        {
          name: '小紫加盟服务商合作协议',
          url: this.sourceUrl + 'jmxy.htm',
        },
      ],
    };
  },
};
</script>
<style scoped lang='scss'>
/* @import url(); 引入css类 */

/deep/ .top {
  display: flex;

  .left {
    width: 60px;
    height: 60px;
    background: #E3ECFF;
    border-radius: 4px;
    font-size: 18px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: #1A66FF;
    line-height: 60px;
    text-align: center;
    margin-right: 10px;
  }

  .right {
    padding-left: 10px;

    h3 {
      font-family: PingFangSC-Semibold;
      font-size: 18px;
      color: #1F2733;
      letter-spacing: 0;
      text-align: left;
      line-height: 32px;
      margin-bottom: 0;
      font-weight: 600;
    }

    p {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #5F6A7A;
      letter-spacing: 0;
      text-align: left;
      line-height: 20px;
      margin-bottom: 0;
    }
  }
}

/deep/.el-tabs__header {
  margin-bottom: 0;

  .el-tabs__item {
    height: auto;
    padding-bottom: 6px;
  }
}

.approveBtn {
  color: #237FFA;
  font-size: 14px;
  cursor: pointer;
}

/deep/ .auditContainer.content_block {
  background: #FEF0F0;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 10px 24px;

  h4 {
    font-size: 14px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    color: #FF5959;
    line-height: 14px;
    margin-bottom: 8px;
  }

  div {
    font-size: 12px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #FF5959;
  }
}
</style>
