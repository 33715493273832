<template>
  <ContentBlock>
    <div class="top">
      <b>基本信息</b>
      <template v-if="!isDisabled">
        <div class="btnContainer" v-if="isEditing">
          <el-button type="default" size="mini"  @click="handleCancel" v-if="!isShowEditing">取消</el-button>
          <el-button type="primary" size="mini" @click="handleSave" v-if="!isShowEditing">保存</el-button>
          <el-button type="primary" size="mini" @click="handleUpdate" v-else>保存</el-button>
        </div>
        <div class="btnContainer" v-else>
          <el-button type="primary" plain icon="el-icon-edit" size="mini" @click="handleEdit">编辑</el-button>
        </div>
      </template>
    </div>
    <el-form :model="form" :rules="rules" ref="JZForm" label-position="right" label-width="100px" style="margin-top: 10px;" v-if="isEditing">
      <el-row>
        <el-col :span="8">
          <el-form-item label="企业类型" prop="type">
            <el-select v-model="form.type" placeholder="请选择企业类型">
              <el-option :label="op" :value="op" v-for="op in  typeOptions" :key="op"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="成立日期" prop="establishTime">
            <el-date-picker

              v-model="form.establishTime"
              type="date"
              placeholder="请选择成立日期"
              value-format="yyyy-MM-dd"
              style="width:100%"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="法人" prop="legalUserName">
            <el-input

              v-model="form.legalUserName"
              placeholder="请输入法人"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="注册资金" prop="registeredCapital">
            <el-input v-model="form.registeredCapital" placeholder="请输入注册资金(万元)"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="实缴资本" prop="paidInCapital">
            <el-input v-model="form.paidInCapital" placeholder="请输入实缴资本(万元)"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="人员规模" prop="scale">
            <el-input

              v-model.number="form.scale"
              placeholder="请输入人员规模"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="参保人数" prop="insuredNumber">
            <el-input

              v-model.number="form.insuredNumber"
              placeholder="请输入参保人数"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="16">
          <el-form-item label="所在地区" prop="registerProvinceId">
            <area-cascader

              :provinceId.sync="form.registerProvinceId"
              :provinceName.sync="form.registerProvinceName"
              :cityId.sync="form.registerCityId"
              :cityName.sync="form.registerCityName"
              placeholder="请选择所在地区"
              :areaId.sync="form.registerAreaId"
              :areaName.sync="form.registerAreaName"
            />
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-form-item label="详细地址" prop="registerAddrs">
            <el-input

              v-model="form.registerAddrs"
              placeholder="请输入详细地址"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-form-item label="经营范围" prop="businessRange">
            <el-input
              v-model="form.businessRange"

              placeholder="请输入经营范围"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

    </el-form>
    <el-descriptions :colon="false" :column="3" style="margin-top: 10px;" v-else>
      <el-descriptions-item label="企业类型" labelClassName="warningLabel" contentClassName="warningContent">{{form.type}}</el-descriptions-item>
      <el-descriptions-item label="成立日期" labelClassName="warningLabel" contentClassName="warningContent">{{form.establishTime}}</el-descriptions-item>
      <el-descriptions-item label="法人" labelClassName="warningLabel" contentClassName="warningContent">{{form.legalUserName}}</el-descriptions-item>
      <el-descriptions-item label="注册资金" labelClassName="warningLabel" contentClassName="warningContent">{{form.registeredCapital}}</el-descriptions-item>
      <el-descriptions-item label="实缴资本" labelClassName="warningLabel" contentClassName="warningContent">{{form.paidInCapital}}</el-descriptions-item>
      <el-descriptions-item label="人员规模" labelClassName="warningLabel" contentClassName="warningContent">{{form.scale}}</el-descriptions-item>
      <el-descriptions-item label="参保人数" :span="3" labelClassName="warningLabel" contentClassName="warningContent">{{form.insuredNumber}}</el-descriptions-item>
      <el-descriptions-item label="注册地址" :span="3" labelClassName="warningLabel" contentClassName="warningContent">{{form.registerProvinceName}}{{form.registerCityName}}{{form.registerAreaName}}{{form.registerAddrs}}</el-descriptions-item>
      <el-descriptions-item label="经营范围" :span="3" labelClassName="warningLabel" contentClassName="warningContent">
        <span>
          {{form.businessRange}}
        </span>
      </el-descriptions-item>
    </el-descriptions>
  </ContentBlock>
  <!-- <ContentBlock v-else>
    <el-empty description="暂未维护数据" :image="require('@/assets/empty.png')"></el-empty>
  </ContentBlock> -->
</template>

<script>
import AreaCascader from '@/components/common/areaCascader';
import {updateTenantInformation} from '@/api/systemSetting';
export default {
  name: '',
  props: {
    isShowEditing: {
      type: Boolean,
      default () {
        return false;
      },
    },
    currentInfo: {
      type: Object,
      default () {
        return {};
      },
    },
    isDisabled: {
      type: Boolean,
      default () {
        return false;
      },
    },
  },
  components: {
    AreaCascader,
  },
  methods: {
    handleEdit () {
      this.isEditing = true;
    },
    handleCancel () {
      this.isEditing = false;
    },
    handleUpdate () {
      this.$refs.JZForm.validate(valid =>{
        if (valid) {
          this.$emit('updateData', this.form, 'tenantBaseInfoRespDTO');
        }
      });
    },
    handleSave () {
      this.$refs.JZForm.validate(valid =>{
        if (valid) {
          if (this.$route.name === 'CorporateCertification') {
            this.$emit('updateData', this.form, 'tenantBaseInfoRespDTO');
          } else {
            let currentParams = {
              dataType: '1',
              tenantBaseInfoReqDTO: this.form,
            };
            updateTenantInformation(currentParams).then(() => {
              this.isEditing = false;
              this.$emit('updatePage');
            });
          }
        }
      });
    },
    async handleValidateForm () {
      try {
        return await this.$refs.JZForm.validate();
      } catch (error) {
        return false;
      }
    },
  },
  watch: {
    isShowEditing: {
      handler () {
        this.isEditing = this.isShowEditing;
      },
      immediate: true,
      deep: true,
    },
    currentInfo: {
      handler (val) {
        if (val && val.tenantBaseInfoRespDTO) {
          let info = val.tenantBaseInfoRespDTO;
          this.form = {
            ...info,
            establishTime: this.$options.filters['date-format'](info.establishTime, 'YYYY-MM-DD'),
          };
        }
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    rules () {
      return {
        registerProvinceId: [
          {
            required: true,
            message: '请选择所在地区',
            trigger: ['blur', 'change'],
          },
        ],
        registerAddrs: [
          {
            required: true,
            message: '请输入详细地址',
            trigger: ['blur', 'change'],
          },
        ],
      };
    },
  },
  data () {
    return {
      hansWord: true,
      isEditing: this.isShowEditing,
      typeOptions: ['有限责任公司', '股份有限公司', '国企', '外商投资企业', '个人独立企业', '合伙制企业', '个体工商户', '加盟企业', '集体所有制', '有限合伙', '普通合伙'],
      form: {
        type: '',
        establishTime: '',
        legalUserName: '',
        registeredCapital: '',
        paidInCapital: '',
        scale: '',
        insuredNumber: '',
        registerProvinceId: '',
        registerProvinceName: '',
        registerCityId: '',
        registerCityName: '',
        registerAreaId: '',
        registerAreaName: '',
        registerAddrs: '',
        businessRange: '',
      },
    };
  },
};
</script>
<style scoped lang='scss'>
/* @import url(); 引入css类 */
.content_block{
  padding: 0 20px 20px 20px;
  min-height: calc(100vh - 420px);
  .top{
    height: 40px;
    line-height: 40px;
    display: flex;
    .btnContainer{
      flex: 1;
      text-align:right;
    }
  }
}
/deep/ .warningLabel{
  width: 100px;
  text-align: right;
  display: inline-block;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #929AA6;
  letter-spacing: 0;
  text-align: right;
  line-height: 20px;
}
/deep/ .warningContent{
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #1F2733;
  letter-spacing: 0;
  text-align: left;
  line-height: 20px;
  display: flex;
}
</style>
